<template>
  <RefuelingList />
</template>

<script>
import RefuelingList from '../components/RefuelingList.vue'

export default {
  name: 'Fueling',
  components: {
    RefuelingList,
  }
}
</script>