<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loading"
        sort-by="date"
        :sort-desc="true"
        multi-sort
        class="elevation-1"
    >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>Заправки</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                dark
                class="mb-2"
                @click.stop="newItem"
            >
              +
            </v-btn>

            <RefuelingEditDialog
                @create="getDataFromApi"
                @update="getDataFromApi"
                ref="formEdit"
            />
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item.id)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="getDataFromApi"
          >
            Обновить
          </v-btn>
        </template>


      <template v-slot:item.mileage="{ value }">
        {{ value ? value : '-' }}
      </template>
      <template v-slot:item.station="{ value }">
        {{ value ? value.name : '-' }}
      </template>
      <template v-slot:item.volume="{ item }">
        {{ item.volume / 100 }} л <br />
        {{ item.costPerLiter / 100 }} за литр <br />
        на {{ item.totalCost / 100 }} р
      </template>
      <template v-slot:item.date="{ value }">
        {{ value | fmtDate }}
      </template>
      <template v-slot:item.createdAt="{ value }">
        {{ value | fmtDate }}
      </template>
    </v-data-table>

    <DeleteDialog
        :api="api"
        title="Вы уверены что хотите удалить заправку?"
        notifyText="Заправка удалена"
        @update="getDataFromApi"
        ref="formDelete"
    />
  </div>
</template>

<script>
import crudApi from '../api/refueling.js'
import RefuelingEditDialog from '../components/RefuelingEditDialog.vue'
import DeleteDialog from '../components/DeleteDialog.vue'

export default {
  name: 'RefuelingList',
  data: () => ({
    loading: true,
    totalCount: 0,
    api: crudApi,
    items: [],
    options: {},
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      { text: 'Дата', value: 'date' },
      { text: 'Место', value: 'station' },
      { text: 'Пробег (км)', value: 'mileage' },
      { text: 'Количество', value: 'volume' },
      { text: 'Дата добавления', value: 'createdAt' },
      { text: 'Действия', value: 'actions', sortable: false },
    ],
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    newItem () {
      this.$refs.formEdit.showNewDialog();
    },
    editItem (item) {
      this.$refs.formEdit.showEditDialog(item);
    },
    deleteItem (itemId) {
      this.$refs.formDelete.showDialog(itemId);
    },
    getDataFromApi () {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      crudApi.getItems(sortBy, sortDesc, page, itemsPerPage).then(response => {
        this.totalCount = response.data.count
        this.items = response.data.items
      }).catch(() => {
        this.totalCount = 0
        this.items = []
      }).finally(() => this.loading = false)
    },
  },
  components: {
    RefuelingEditDialog,
    DeleteDialog,
  }
}
</script>