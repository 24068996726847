<template>
  <div>
    <v-dialog
        v-model="show"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-alert
              type="error"
              v-if="error !== null"
              v-text="error"
          />

          <v-container>
            <v-row>
              <v-col>
                <v-datetime-picker
                    label="Дата"
                    clearText="Очистить"
                    v-model="editedItem.date"
                    date-format="yyyy-MM-dd"
                    time-format="HH:mm:ss"
                    :time-picker-props="timePickerProps"
                    :error-messages="editFormDateErrors"
                />
              </v-col>

            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                    v-model="editedItem.station"
                    :items="stations"
                    clearable
                    label="Станция"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    v-model="editedItem.mileage"
                    label="Пробег"
                    suffix="км"
                    :error-messages="editFormMileageErrors"
                    :disabled="editedItem.noMileage"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-switch
                    v-model="editedItem.noMileage"
                    label="Пробег не зафиксирован"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    v-model="editedItem.volume"
                    label="Заправлено топлива"
                    suffix="л"
                    :error-messages="editFormVolumeErrors"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                    :disabled="!canCalculateVolume"
                    @click="calculateVolume"
                >
                  Рассчитать
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    v-model="editedItem.costPerLiter"
                    label="Цена за литр"
                    prefix="₽"
                    :error-messages="editFormCostPerLiterErrors"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                    :disabled="!canCalculateCostPerLiter"
                    @click="calculateCostPerLiter"
                >
                  Рассчитать
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    v-model="editedItem.totalCost"
                    label="Итоговая цена"
                    prefix="₽"
                    :error-messages="editFormTotalCostErrors"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                    :disabled="!canCalculateTotalCost"
                    @click="calculateTotalCost"
                >
                  Рассчитать
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="loading">
          Выполняется запрос
          <v-progress-linear
              indeterminate
              color="blue"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>

        <v-card-actions v-if="!loading && !$v.editedItem.$invalid">
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Отмена
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../api/refueling.js'
import stationApi from '../api/station.js'
import {required, between, decimal, integer} from 'vuelidate/lib/validators'
import moment from 'moment';

export default {
  name: 'RefuelingEditDialog',
  data: () => ({
    show: false,
    loading: false,
    error: null,
    editedId: -1,
    editedItem: {
      date: null,
      station: null,
      mileage: null,
      noMileage: false,
      volume: null,
      costPerLiter: null,
      totalCost: null,
    },
    defaultItem: {
      date: null,
      station: null,
      mileage: null,
      noMileage: false,
      volume: null,
      costPerLiter: null,
      totalCost: null,
    },
    stations: [],
    timePickerProps: {
      useSeconds: true,
      format: "24hr",
    }
  }),
  validations() {
    let validators = {
      editedItem: {
        mileage: {
          integer,
          between: between(1, 1000000),
        },
        volume: {
          required,
          decimal,
          between: between(0.01, 100),
        },
        costPerLiter: {
          required,
          decimal,
          between: between(0.01, 150),
        },
        totalCost: {
          required,
          decimal,
          between: between(0.01, 10000),
        },
        date: {
          required,
        },
      },
    }

    if (!this.editedItem.noMileage) {
      validators.editedItem.mileage['required'] = required
    }

    return validators
  },
  computed: {
    formTitle () {
      return this.editedId === -1 ? 'Новая заправка' : 'Изменение заправки с ID=' + this.editedId
    },
    editFormMileageErrors () {
      if (this.$v.editedItem.mileage.$invalid) {
        if (this.$v.editedItem.mileage.between) {
          return 'Значение должно быть между ' + this.$v.editedItem.mileage.$params.between.min + ' и ' + this.$v.editedItem.mileage.$params.between.max;
        } else {
          return 'Некорректное значение';
        }
      }

      return null;
    },
    editFormVolumeErrors () {
      if (this.$v.editedItem.volume.$invalid) {
        if (this.$v.editedItem.volume.required) {
          return 'Обязательное значение';
        } else if (this.$v.editedItem.volume.between) {
          return 'Значение должно быть между ' + this.$v.editedItem.volume.$params.between.min + ' и ' + this.$v.editedItem.volume.$params.between.max;
        } else {
          return 'Некорректное значение';
        }
      }

      return null;
    },
    editFormCostPerLiterErrors () {
      if (this.$v.editedItem.costPerLiter.$invalid) {
        if (this.$v.editedItem.costPerLiter.required) {
          return 'Обязательное значение';
        } else if (this.$v.editedItem.costPerLiter.between) {
          return 'Значение должно быть между ' + this.$v.editedItem.costPerLiter.$params.between.min + ' и ' + this.$v.editedItem.costPerLiter.$params.between.max;
        } else {
          return 'Некорректное значение';
        }
      }

      return null;
    },
    editFormTotalCostErrors () {
      if (this.$v.editedItem.totalCost.$invalid) {
        if (this.$v.editedItem.totalCost.required) {
          return 'Обязательное значение';
        } else if (this.$v.editedItem.totalCost.between) {
          return 'Значение должно быть между ' + this.$v.editedItem.totalCost.$params.between.min + ' и ' + this.$v.editedItem.totalCost.$params.between.max;
        } else {
          return 'Некорректное значение';
        }
      }

      return null;
    },
    editFormDateErrors () {
      if (this.$v.editedItem.date.$invalid) {
        if (this.$v.editedItem.date.required) {
          return 'Обязательное значение';
        } else {
          return 'Некорректное значение';
        }
      }

      return null;
    },
    canCalculateVolume () {
      return !this.$v.editedItem.costPerLiter.$invalid && !this.$v.editedItem.totalCost.$invalid;
    },
    canCalculateCostPerLiter () {
      return !this.$v.editedItem.volume.$invalid && !this.$v.editedItem.totalCost.$invalid;
    },
    canCalculateTotalCost () {
      return !this.$v.editedItem.costPerLiter.$invalid && !this.$v.editedItem.volume.$invalid;
    },
  },
  methods: {
    close () {
      this.show = false
      this.error = null
      this.loading = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedId = -1
      })
    },
    showNewDialog () {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedId = -1
      this.error = null
      this.loading = false
      this.show = true
    },
    showEditDialog (item) {
      this.editedItem = Object.assign({noMileage: (item.mileage === null)}, item)
      this.editedItem.costPerLiter /= 100;
      this.editedItem.volume /= 100;
      this.editedItem.totalCost /= 100;
      this.editedItem.station = this.editedItem.station?.id;
      this.editedItem.date = moment(this.editedItem.date).format('YYYY-MM-DD HH:mm:ss');

      this.editedId = item.id
      this.error = null
      this.loading = false
      this.show = true
    },
    save () {
      this.error = null
      this.$v.$touch();

      if (this.$v.editedItem.$invalid) {
        return;
      }

      this.loading = true

      let fields = {
        station: this.editedItem.station,
        volume: Math.trunc(this.editedItem.volume * 100),
        costPerLiter: Math.trunc(this.editedItem.costPerLiter * 100),
        totalCost: Math.trunc(this.editedItem.totalCost * 100),
        mileage: !this.editedItem.noMileage ? this.editedItem.mileage : null,
        date: moment(this.editedItem.date).toISOString(),
      }

      if (this.editedId > -1) {
        api
            .updateItem(this.editedId, fields)
            .then((response) => {
              this.close()
              this.$notify({
                type: 'info',
                text: 'Заправка обновлена',
              })
              this.$emit('update', response.data.item)
            })
            .catch((reason) => {
              this.error = typeof reason === 'string' ? reason : 'Неизвестная ошибка'
            })
            .finally(() => this.loading = false)
      } else {
        api
            .createItem(fields)
            .then((response) => {
              this.close()
              this.$notify({
                type: 'info',
                text: 'Заправка добавлена',
              })
              this.$emit('create', response.data.item)
            })
            .catch((reason) => {
              this.error = typeof reason === 'string' ? reason : 'Неизвестная ошибка'
            })
            .finally(() => this.loading = false)
      }
    },
    loadStations () {
      this.stations = []

      stationApi.getAll().then(response => {
        response.data.items.sort((f, s) => f.name > s.name ? 1 : (f.name < s.name ? -1 : 0))

        for (let i in response.data.items) {
          let station = response.data.items[i];

          this.stations.push({
            text: station.name,
            value: station.id,
          })
        }
      })
    },
    calculateVolume() {
      let volume = Math.trunc(this.editedItem.totalCost * 100) / Math.trunc(this.editedItem.costPerLiter * 100)

      this.editedItem.volume = +volume.toFixed(2)
    },
    calculateTotalCost() {
      let costMultiple = Math.trunc(Math.trunc(this.editedItem.volume * 100) * Math.trunc(this.editedItem.costPerLiter * 100) / 100)

      this.editedItem.totalCost = +(costMultiple / 100).toFixed(2)
    },
    calculateCostPerLiter() {
      let cost = Math.trunc(this.editedItem.totalCost * 100) / Math.trunc(this.editedItem.volume * 100)

      this.editedItem.costPerLiter = +cost.toFixed(2)
    },
  },
  mounted () {
    this.loadStations()
  },
}
</script>