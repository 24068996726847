var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"sort-by":"date","sort-desc":true,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Заправки")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.newItem($event)}}},[_vm._v(" + ")]),_c('RefuelingEditDialog',{ref:"formEdit",on:{"create":_vm.getDataFromApi,"update":_vm.getDataFromApi}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDataFromApi}},[_vm._v(" Обновить ")])]},proxy:true},{key:"item.mileage",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? value : '-')+" ")]}},{key:"item.station",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? value.name : '-')+" ")]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.volume / 100)+" л "),_c('br'),_vm._v(" "+_vm._s(item.costPerLiter / 100)+" за литр "),_c('br'),_vm._v(" на "+_vm._s(item.totalCost / 100)+" р ")]}},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("fmtDate")(value))+" ")]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("fmtDate")(value))+" ")]}}])}),_c('DeleteDialog',{ref:"formDelete",attrs:{"api":_vm.api,"title":"Вы уверены что хотите удалить заправку?","notifyText":"Заправка удалена"},on:{"update":_vm.getDataFromApi}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }